@import "core.pcss";

#nav li.active a {
    @apply underline;
}

#cover img {
    image-rendering: -webkit-optimize-contrast;
}

#logo-container, #logo-container div, #logo-container img {
    will-change: transform;
}

#cover h2 span {
    opacity: 0;
    max-width: 0;

    animation: showPlus 1s 500ms linear forwards;
    @keyframes showPlus {
        0% {
            opacity: 0.25;
            max-width: 0;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 1;
            max-width: 1em;
        }
    }
}

@media (pointer:coarse) {
    #logo-container {
        @apply transition;
        @apply duration-[0.15];
        @apply ease-linear;
    }
}

.drop-shadow-cover-seperator {
    filter: drop-shadow(0 3px 5px rgba(0, 0, 0, 15%));
}

#portfolio figure {
    @apply relative;
    @apply rounded;

    img {
        @apply transition;
        @apply duration-200;
    }

    .portfolio-details-button-container {
        @apply hidden;
        @apply absolute;
        @apply top-1/2;
        @apply left-1/2;
        @apply -translate-y-1/2;
        @apply -translate-x-1/2;

        a {
            @apply bg-magenta;
            @apply text-textOnMagenta;
            @apply px-5;
            @apply py-3;
            @apply rounded;
            @apply hover:transition;
            @apply hover:duration-200;
            @apply hover:bg-magentaDarkened;
        }
    }

    .portfolio-details-container {
        @apply relative;
        @apply bg-magenta;
        @apply rounded-br;
        @apply rounded-bl;
        @apply text-textOnMagenta;
        @apply p-4;
        @apply pt-2;
        @apply text-left;
        @apply overflow-hidden;
        @apply transition-transform;
        @apply duration-500;
        @apply origin-top;
        @apply h-52;

        .portfolio-details-close-button {
            @apply hidden;
            @apply 2xl:block;
            @apply absolute;
            @apply right-2;
            @apply top-2;
            @apply text-textOnMagenta;
            @apply hover:text-textOnMagentaDarkened;
        }

        .portfolio-details-footer {
            @apply absolute;
            @apply bottom-4;
        }
    }

    @media (min-width: 1535px) {
        .overflow-hidden:hover {
            img {
                @apply scale-150;
                @apply rotate-3;
                @apply filter;
                /*@apply blur-sm;*/
            }

            .portfolio-details-button-container {
                @apply block;
            }
        }
    }

}